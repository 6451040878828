// COMPONENTS
import React, { createElement } from 'react'
// ICON SET
import * as Icons from 'react-icons/fa'

// Font Awesome Icons component
// const Icon = ({ name, size='100px' }) => {
const Icon = ({ name, size='2em'}) => {
  let icon = null
  try{
    icon = createElement(Icons[name], { className: 'ReactIcon', size })
    // icon = createElement(Icons[name], { style: { fontSize: '0.5em' }})
  } catch (err) { console.err(err) }

  return (<>{icon ? icon : <></>}</>)
}

export default Icon
