// COMPONENTS
import React from 'react'
import ContentContainer from 'layout/ContentContainer'
import Endcap from 'layout/Endcap'

// Landing page section component
const Section = ({ content, children }) => {
  const { cntrType='default', id='section', isSPA=false, inverted=false, endcap='none', title, subtitle, bgVideo, styles, type='section' } = content
  if(isSPA){
    return (
      <ContentContainer id={id} cntrType={cntrType}>
        <ContentContainer id={`${id}-content`} type={type} styles={styles} inverted={inverted}>
          { bgVideo &&
          <video playsInline autoPlay muted loop style={bgVideo.style}> 
            <source src={bgVideo.url} itype='video/mp4'/> 
          </video>
          }
          {title && <h1 className='pt-5'>{title}</h1>} 
          {subtitle && <h5>{subtitle}</h5>}
          {children}
        </ContentContainer>
        <ContentContainer
        id={`${id}-endcap`}
        type='sectionEndcap'
        inverted={inverted}>
          <Endcap type={endcap} inverted={inverted} />
        </ContentContainer>
      </ContentContainer>
    )
  } else {
    return(
      <ContentContainer id={`${id}-spa-content`} cntrType={cntrType} type={type} styles={styles} inverted={inverted}>
        { bgVideo &&
        <video playsInline autoPlay muted loop style={bgVideo.style}> 
          <source src={bgVideo.url} itype='video/mp4'/> 
        </video>
        }
        {title && <h1 className='pt-5'>{title}</h1>} 
        {subtitle && <h5>{subtitle}</h5>}
        {children}
      </ContentContainer>
    )
  }
}

export default Section
